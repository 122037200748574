import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { Link } from 'gatsby';
import * as styles from '../../../styles/auth/Signup.module.scss';
import { FormData } from '../../../pages/signup';
import { InviteDetails } from '../../../types/inviteDetails';

type Props = {
  formData: FormData,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  getOtpHandler: () => void,
  inviteeSignupFlow?: boolean,
  inviterDetails?: InviteDetails | null,
  accountExists?: boolean
  userCountry?: string
}

const InviteSignupPersonalInfo: React.FC<Props> = ({
  formData,
  setFormData,
  getOtpHandler,
  inviteeSignupFlow,
  inviterDetails,
  accountExists,
  userCountry,
}) => {
  const [isFormComplete, setFormComplete] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    password: false,
    email: false,
  });

  const checkPasswordValidity = (password: string) => {
    if (password.length >= 8) {
      return true;
    }
    return false;
  };

  const setValidation = (field: string, value: string | boolean) => {
    // Condition to avoid  terms and promotions key in error state
    if (!['terms', 'promotions'].includes(field)) {
      setErrors((prevState) => ({
        ...prevState,
        [field]: !value,
      }));
    }
  };

  const inputValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'password': setValidation(name, checkPasswordValidity(value));
        break;
      default: setValidation(name, !!value);
    }
  };

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (['terms', 'promotions'].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }

    inputValidation(e);
  };

  const checkFormValidity = () => {
    let valid = true;
    const {
      firstName,
      lastName,
      password,
      terms,
    } = formData;
    // check if fields are entered & valid
    if (!firstName
      || !lastName
      || !checkPasswordValidity(password)
      || !terms) {
      valid = false;
    }
    setFormComplete(valid);
  };

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  useEffect(() => {
    if (inviterDetails) {
      setFormData((prevState) => ({
        ...prevState,
        email: inviterDetails.email.trim(),
      }));
    }
  }, [inviterDetails]);

  return (
    <div className={`${styles.innerContainer} ${accountExists ? styles.accountExistsContainer : ''}`}>
      <h3 className={styles.inviteeHeading}>Join your team.</h3>
      <p className={styles.inviterTitle}>
        You were invited by
        {' '}
        {inviterDetails?.inviter?.email}
        .
      </p>
      <form className={styles.form} id="self-serve-starter-plan" onSubmit={getOtpHandler}>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor="firstName">
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={formData.firstName ? formData.firstName : ''}
                autoComplete="off"
                placeholder="First Name"
                className={formData.firstName && (!errors.firstName
                  ? styles.valid : styles.invalid)}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="lastName">
              <input
                id="lastName"
                name="lastName"
                value={formData.lastName ? formData.lastName : ''}
                type="text"
                autoComplete="off"
                placeholder="Last Name"
                className={formData.lastName && (!errors.lastName
                  ? styles.valid : styles.invalid)}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">
            <input
              id="email"
              name="email"
              type="email"
              value={inviterDetails?.email}
              className={styles.valid}
              readOnly
            />
          </label>
        </div>
        <p className={styles.disabledDomainInfo}>
          Company email required
        </p>
        {userCountry
        && <input className={styles.countryHiddenInput} type="text" name="country" id="country" value={userCountry} />}
        {
          accountExists && (
            <p className={styles.accountExists}>
              * Email already exists! Try to
              {' '}
              <Link
                state={{
                  inviteeSignupFlow,
                }}
                to="/login"
              >
                log-in.
              </Link>
            </p>
          )
        }
        <div className={`${styles.formGroup} ${styles.hrLine}`}>
          <label htmlFor="password">
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password ? formData.password : ''}
              autoComplete="off"
              placeholder="Password"
              className={formData.password && (!errors.password
                ? styles.valid : styles.invalid)}
              onChange={handleChange}
            />
          </label>
          <h6 className={styles.passwordCriteria}>Please enter a minimum of 8 characters</h6>
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.checkbox}
            name="terms"
            id="self_serve_terms"
            checked={formData.terms}
            onChange={handleChange}
            value={formData.terms ? 'yes' : 'no'}
          >
            By signing up, you agree to our
            {' '}
            <a className={styles.terms} href="https://www.upskillist.pro/terms/">Terms & Conditions</a>
          </Checkbox>
          <Checkbox
            name="promotions"
            id="self_seve_promotions"
            checked={formData.promotions}
            onChange={handleChange}
            className={styles.checkbox}
            value={formData.promotions ? 'yes' : 'no'}
          >
            Receive promotional emails about events & offers.
          </Checkbox>
        </div>
        <button
          className={styles.cta}
          disabled={!isFormComplete}
          type="submit"
        >
          Join now
        </button>
      </form>
    </div>
  );
};

export default InviteSignupPersonalInfo;
