import { RouteComponentProps } from '@reach/router';
import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Amplify, { API } from 'aws-amplify';
import { Spin, message as notify } from 'antd';
import { navigate } from 'gatsby';
import SignupHeader from '../Shared/SignupHeader';
import * as styles from '../../../styles/auth/Signup.module.scss';
import awsconfig from '../../../backendConfigProvider';
import {
  FormData, Payload, SignupView,
} from '../../../pages/signup';
import { CognitoSignupUserDetails } from '../../../types/amplifyUserSignup.type';
import { amplifySignUp } from '../../../services/SignupService';
import InviteSignupPersonalInfo from './InviteSignupPersonalInfo';
import SignupOtpView from '../Shared/SignupOtpView';
import Footer from '../../Shared/Footer';
import { InviteDetails } from '../../../types/inviteDetails';
import { getCurrentUser, logout } from '../../Shared/Services/AuthService';

Amplify.configure(awsconfig);

const defaultFormData = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  phoneNumber: '',
  terms: false,
  promotions: false,
};

interface Props extends RouteComponentProps {
id?: string
}

const InviteeSignupView:React.FC<Props> = ({ id }) => {
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [signupView, setSignupView] = useState<SignupView>(SignupView.LOADING);
  const [inviterDetails, setInviterDetails] = useState<InviteDetails | null>(null);
  const [accountExists, setAccountExists] = useState(false);

  const createPayload: () => Payload = () => {
    const fullName = `${formData.firstName} ${formData.lastName}`;
    const payload = {
      name: fullName,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      promotions: formData.promotions,
    };
    return payload;
  };

  const signupHandler = async (payload: Payload) => {
    try {
      const { data, error }:
      {data: CognitoSignupUserDetails, error: any} = await amplifySignUp(payload);

      if (error) {
        if (error.code === 'UsernameExistsException') {
          setSignupView(SignupView.PROFILE_DETAILS_VIEW);
          setAccountExists(true);
          notify.error('Account already exists', 5);
        } else if (error.message.includes('ERROR_CODE:DOMAIN_NOT_ALLOWED')) {
          setSignupView(SignupView.PROFILE_DETAILS_VIEW);
          setFormData(defaultFormData);
          notify.error('Company email required.', 5);
        } else {
          setFormData(defaultFormData);
          setSignupView(SignupView.PROFILE_DETAILS_VIEW);
          notify.error(error.message, 5);
        }
      } else {
        setSignupView(SignupView.OTP_VIEW);
      }
    } catch (er: any) {
      setFormData(defaultFormData);
      setSignupView(SignupView.PROFILE_DETAILS_VIEW);
      notify.error(er?.message, 5);
    }
  };

  const getOtpHandler = async () => {
    if (inviterDetails?.status === 'ACCEPTED') {
      notify.error('Account already exists.', 3);
      return;
    }

    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        logout(false, false);
      }
    } catch (error) {
      console.log('No current logged in user present');
    }

    setSignupView(SignupView.LOADING);
    const payload = createPayload();
    try {
      signupHandler(payload);
    } catch (error: any) {
      setSignupView(SignupView.PROFILE_DETAILS_VIEW);
      notify.error('Request failed', 5);
    }
  };

  const fetchInviterDetails = async () => {
    const apiName = 'enterpriseappgetinvitebyid';
    const path = `/invite?id=${id}`;
    API.get(apiName, path, {}).then(async (response: any) => {
      const invitationDetails = response?.items[0];
      if (invitationDetails) {
        if (invitationDetails?.status === 'ACCEPTED') {
          notify.error('Account already exists.', 3);
          setAccountExists(true);
        }
        setInviterDetails(invitationDetails);
        setSignupView(SignupView.PROFILE_DETAILS_VIEW);
      } else {
        setSignupView(SignupView.WRONG_INVITE_LINK);
      }
    });
  };

  useEffect(() => {
    fetchInviterDetails();
  }, []);

  return (
    <>
      <SignupHeader />
      <div className={`${styles.signup} ${signupView === SignupView.WRONG_INVITE_LINK ? styles.wrongLink : ''}`}>
        <StaticImage
          className={styles.signupBackground}
          src="../../../../static/images/shared/signup-background.png"
          alt="Dashed lines as background"
        />
        {
              signupView === SignupView.WRONG_INVITE_LINK
              && (
              <div className={styles.wrongInviteLinkContainer}>
                <h3 className={styles.oopsText}>Oops!</h3>
                <h1 className={styles.wrongInviteLinkTitle}>
                  Wrong invite link.
                  {' '}
                  <h3>☹️</h3>
                </h1>
                <p className={styles.wrongInviteLinkBody}>
                  Looks like you have received a wrong invite link.
                  Please contact your admin for a correct link.
                </p>
                <button onClick={() => navigate('https://www.upskillist.pro/')} className={styles.exploreHomeCta} type="button">Explore home</button>
              </div>
              )
          }
        {
             signupView !== SignupView.WRONG_INVITE_LINK
             && (
             <>
               <div className={styles.signupLeft}>
                 <div className={styles.signupTitle}>
                   <h2 className={styles.brandTitleInvite}>
                     Your team
                   </h2>
                   <h1 className={styles.titleText}>
                     Welcomes you!
                   </h1>
                 </div>
                 <StaticImage
                   className={styles.signupImage}
                   src="../../../../static/images/shared/invitee-signup.png"
                   alt="A group of people discussing and staring at a board"
                 />
                 <p className={styles.upskillYourTeam}>
                   Start upskilling your team now!
                 </p>
                 <ul className={styles.benefitsList}>
                   <li>Get access to All our courses.</li>
                   <li>Invite your team.</li>
                   <li>See your performance.</li>
                 </ul>
               </div>
               <div className={`${styles.signupContainer} ${styles.inviteContainer}`}>
                 {
                  signupView === SignupView.NO_INVITES_LEFT
                  && (
                  <div className={styles.noInvitesContainer}>
                    <div className={styles.noInvites}>
                      <h3 className={styles.noInvitesTitle}>
                        No invites left!
                        {' '}
                        <h3>☹️</h3>
                      </h3>
                      <p className={styles.noInvitesBody}>
                        We have a great news for you! Your entire team
                        is here and we are glad to have you too.
                        Unfortunately, all the invites are exhausted,
                        please contact your admin for your licence.
                      </p>
                      <button
                        className={styles.requestAdminCta}
                        type="button"
                      >
                        Request your admin
                      </button>
                    </div>
                  </div>
                  )
              }
                 <div className={styles.formContainer}>
                   {
                  signupView === SignupView.LOADING
                  && (
                  <div className={styles.spinnerContainer}>
                    <div className={styles.spinner}>
                      <Spin />
                    </div>
                  </div>
                  )
                }
                   {signupView === SignupView.PROFILE_DETAILS_VIEW
                  && (
                    <InviteSignupPersonalInfo
                      inviteeSignupFlow
                      formData={formData}
                      setFormData={setFormData}
                      getOtpHandler={getOtpHandler}
                      inviterDetails={inviterDetails}
                      accountExists={accountExists}
                    />
                  )}
                   {
                  signupView === SignupView.OTP_VIEW
                  && (
                    <SignupOtpView
                      formData={formData}
                      setSignupView={setSignupView}
                      inviterDetails={inviterDetails}
                    />
                  )
                }
                 </div>
                 <p className={styles.loginText}>
                   Already have an account?
                   {' '}
                   <button type="button" onClick={() => navigate('/login')}>Login.</button>
                 </p>
                 <p className={styles.faq}>
                   Check our
                   {' '}
                   <a href="https://support.upskillist.pro/">Business FAQs</a>
                   {' '}
                   for more information
                 </p>
               </div>
             </>
             )
          }
      </div>
      <Footer />
    </>
  );
};

export default InviteeSignupView;
