import React from 'react';
import { Router } from '@reach/router';
import InviteeSignupView from './InviteeSignupView';

const InviteeSignupRouter:React.FC = () => (
  <Router basepath="/invite/">
    <InviteeSignupView path="/:id" />
  </Router>
);

export default InviteeSignupRouter;
